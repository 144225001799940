import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { permissions } from 'src/app/shared/roles';
import { SessionService } from '../../../../shared/service/session.service';
import { async } from 'q';

@Injectable()
export class AuthGuardService {

  constructor(private permissionsGuard: NgxPermissionsService,private session:SessionService) { }

  public async checkViewUser() {
       const getPermission= await this.session.getPermission();
       let permissionVal=getPermission ? getPermission[permissions.viewUser]:undefined;
        return permissionVal ? true :false;
  }

  public async checkviewUserReports() {
    const getPermission= await this.session.getPermission();
       let permissionVal=getPermission ? getPermission[permissions.viewUserReports]:undefined;
        return permissionVal ? true :false;
  }

  public async checkviewPlatformLog() {
    const getPermission= await this.session.getPermission();
       let permissionVal=getPermission ? getPermission[permissions.viewPlatformLog]:undefined;
        return permissionVal ? true :false;
  }

  public async checkviewAdminUser() {
    const getPermission= await this.session.getPermission();
       let permissionVal=getPermission ? getPermission[permissions.viewAdminUser]:undefined;
        return permissionVal ? true :false;
  }

  public async checkviewClientChild() {
    const getPermission= await this.session.getPermission();
       let permissionVal=getPermission ? getPermission[permissions.viewClientChild]:undefined;
        return permissionVal ? true :false;
  }

  public async checkviewClient() {
    const getPermission= await this.session.getPermission();
       let permissionVal=getPermission ? getPermission[permissions.viewClient]:undefined;
        return permissionVal ? true :false;
  }

}