import { Injectable } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ClientTypes } from 'src/app/features/client/models/client.model';
import { UserTypes } from 'src/app/features/user/models/user.model';
import { UserService } from 'src/app/features/user/user.service';
import { convertToObject } from 'typescript';
import { UserSession } from '../type/session.type';
import { AuthService } from 'src/app/modules/auth/_services/auth.service';
import { AdvisoryMappingService } from 'src/app/features/advisory-mapping/advisory-mapping.service';
import { ClientService } from 'src/app/features/client/client.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private detail: any;
  public amplifyInitialState: string = null;

  sessionChanges: BehaviorSubject<UserSession> = new BehaviorSubject<UserSession>(null)
  checkSession: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(
    private userService: UserService,
    private permissionsService: NgxPermissionsService,
    private auth: AuthService,
    private advisoryMappingService: AdvisoryMappingService,
    private clientService: ClientService
  ) { }

  setSession = async (session): Promise<void> => {
    localStorage.setItem("session", JSON.stringify(session));
    this.detail = session;
    let isPermissionsIsSet = await this.loadPermissions();
    this.sessionChanges.next(session)
    this.checkIfPermissionsIsSet(isPermissionsIsSet);
  }

  setSessionDefaultClient = (session): void => {
    localStorage.setItem('sessionDefaultClient', JSON.stringify(session));
  }
  saveToken = (token):void=>{
    localStorage.setItem('importApiToken',token);
  }
  getToken = ():string=>{
   return localStorage.getItem('importApiToken');
  }
  saveIdToken = (token):void=>{
    localStorage.setItem('Id-Token',token);
  }
  saveRefreshToken = (refreshToken):void=>{
    localStorage.setItem('Refresh-Token',refreshToken);
  }
  saveTokenExpiry = (expiryDate):void=>{
    localStorage.setItem('Token-Expiry',expiryDate);
  }
  clearRefreshToken = ():void=>{
    localStorage.removeItem('Refresh-Token');
  }
  clearIdToken = ():void=>{
    localStorage.removeItem('Id-Token');
  }
  clearTokenExpiry = ():void=>{
    localStorage.removeItem('Token-Expiry');
  }
  getIdToken = ():string=> {
   return localStorage.getItem('Id-Token');
  }
  getRefreshToken = ():string=> {
    return localStorage.getItem('Refresh-Token');
   }
   getTokenExpiry = ():string=> {
    return localStorage.getItem('Token-Expiry');
   }
  setSessionOnInit = async (session): Promise<void> => {
    // If local session exists, use that instead
    let localSession = JSON.parse(localStorage.getItem("session"))
    if (localSession) session = localSession
    localStorage.setItem("session", JSON.stringify(session));
    this.detail = session;
    let isPermissionsIsSet = await this.loadPermissions()
    this.sessionChanges.next(session)
    this.checkIfPermissionsIsSet(isPermissionsIsSet);
  }

  checkIfPermissionsIsSet(flag){
    if(!flag){
      console.error("Permissions are not set. Logging the user out.");
      this.clearIdToken();
      this.clearRefreshToken();
      this.clearTokenExpiry();
      this.auth.logout();
    }
  }

  getSession = (): UserSession => {
    this.detail = JSON.parse(localStorage.getItem('session'))
    if (this.detail)
      return this.detail
    else
      return null
  }

  getSessionDefaultClient = (): UserSession => {
    this.detail = JSON.parse(localStorage.getItem('sessionDefaultClient'))
    if (this.detail)
      return this.detail
    else
      return null
  }

  getPermission = (): any => {
    return JSON.parse(localStorage.getItem('userPermission'));
  }

  loadPermissions = () => {
    return new Promise( async resolver => {
      const session=this.getSessionDefaultClient();
      const sessionUpdated = this.getSession();
      if (session) {
        console.log('LOADING PERMISSIONS');
        let typeId = session.currentUserTypeId;
        const extraRoles = [];
        debugger
        if (session.currentClientTypeId == ClientTypes.Restricted) {
          typeId = session.currentUserTypeId == UserTypes.Owner ? UserTypes.Admin : UserTypes.ClientUser;
          if (session.currentUserTypeId == UserTypes.Admin && typeId == UserTypes.ClientUser) extraRoles.push('viewClient', 'viewAdminUser');
        }
        if((session.currentClientTypeId ==ClientTypes.Advisor) && (session.currentUserTypeId == UserTypes.ClientAdmin))
        {
          typeId = UserTypes.AdminAdvisor
          localStorage.setItem("AdminAdvisor",JSON.stringify(ClientTypes.Advisor));
        }
        if((session.currentClientTypeId ==ClientTypes.Advisor) && (session.currentUserTypeId == UserTypes.ClientUser))
        {
          extraRoles.push('viewClient');
          if(sessionUpdated.currentClientId !=session.currentClientId)
          {
            extraRoles.push('viewPlatform','viewUser','viewUserGroup','viewReport','editReport','addPlatform','addUser','addUserGroup','editPlatform','editUser','editUserGroup');
          }
        }
        const AdminAdvisor = parseInt(localStorage.getItem("AdminAdvisor"));
        if((session.currentClientTypeId == ClientTypes.Standard) && session.currentUserTypeId == UserTypes.ClientAdmin){
          extraRoles.push('viewClientChild');
        }
        
        if((AdminAdvisor == ClientTypes.Advisor) && (session.currentUserTypeId == UserTypes.ClientAdmin))
         {
          typeId = UserTypes.AdminAdvisor
        }
  
        else {
          typeId = session.currentUserTypeId == UserTypes.Owner ? UserTypes.Admin : typeId;
        }

        var formData = {
          clientId: this.getSession().currentClientId != null ? this.getSession().currentClientId : "00000000-0000-0000-0000-000000000000"
        }
        forkJoin([this.advisoryMappingService.hasAdvisoryMappingAccess(formData), this.clientService.hasBillingAccesss(formData)]).subscribe(data => {
          if(((data[0] && this.getSession().currentUserTypeId == UserTypes.ClientAdmin)) || 
            this.getSession().currentUserTypeId == UserTypes.Admin){
              extraRoles.push("viewAdvisoryClient", 'viewClientChild');
          }
          if(((data[1] && this.getSession().currentUserTypeId == UserTypes.ClientAdmin)) || 
            this.getSession().currentUserTypeId == UserTypes.Admin){
              extraRoles.push("viewBilling");
          }

          this.userService.initRoles(session.currentUserTypeId,session.currentClientTypeId)
          .subscribe(data => {
            this.permissionsService.loadPermissions([ ...data, ...extraRoles ]); 
            localStorage.setItem("userPermission",JSON.stringify(this.permissionsService.getPermissions()));
            console.log('Permissions', this.permissionsService.getPermissions());
            this.checkSession.next(true);
            return resolver(true);
          })
        })

        // this.checkAdvisoryMappingAccess().then(data => {
        //   if(((data && this.getSession().currentUserTypeId == UserTypes.ClientAdmin)) || 
        //     this.getSession().currentUserTypeId == UserTypes.Admin){
        //       extraRoles.push("viewAdvisoryClient");
        //   }
        //   this.userService.initRoles(session.currentUserTypeId,session.currentClientTypeId)
        //   .subscribe(data => {
        //     this.permissionsService.loadPermissions([ ...data, ...extraRoles ]); 
        //     localStorage.setItem("userPermission",JSON.stringify(this.permissionsService.getPermissions()));
        //     console.log('Permissions', this.permissionsService.getPermissions());
        //     this.checkSession.next(true);
        //     return resolver(true);
        //   })
        // })
      }
      else{
        return resolver(false);
      }
    });
  }

  checkAdvisoryMappingAccess(){
    return new Promise( async resolver => {
      var formData = {
        clientId: this.getSession().currentClientId != null ? this.getSession().currentClientId : "00000000-0000-0000-0000-000000000000"
      }
      this.advisoryMappingService.hasAdvisoryMappingAccess(formData).subscribe(data => {
        return resolver(data);
      });
    })
  };

  checkBillingAccess(){
    return new Promise( async resolver => {
      var formData = {
        clientId: this.getSession().currentClientId
      }
      this.clientService.hasBillingAccesss(formData).subscribe(data => {
        return resolver(data);
      });
    })
  };

  refreshHomeNav = (): string => {
    const session = this.getSession();
    if(session)
    {
      session.currentUserId =='00000000-0000-0000-0000-000000000000' ? session.currentUserTypeId=6:'';

    }
    if (!session) return '';
    switch (session?.currentUserTypeId) {
      case UserTypes.Owner:
      case UserTypes.Admin:
        return '/clients'
      case UserTypes.ClientAdmin:
        if(session.currentClientTypeId ==ClientTypes.Advisor)
        {
          return '/clients'
        }
        return `/clients/${session.currentClientId}`
      case UserTypes.ClientUser:
        if(session.currentClientTypeId ==ClientTypes.Advisor)
        {
          return '/clients'
        }
        return `/users/${session.currentUserId}`
      //case UserTypes.ClientUser:
      // return `/users/${session.currentUserId}`
      case UserTypes.User:
        return `/users/${session.currentUserId}`
      case UserTypes.UnregisteredUser:
        return '/signupwithxeroform'
       
    }

    return '';
  }
}
